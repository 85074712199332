(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace/reveal-link/assets/javascripts/reveal-trigger.js') >= 0) return;  svs.modules.push('/components/marketplace/reveal-link/assets/javascripts/reveal-trigger.js');

'use strict';

function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const {
  withRouter
} = ReactRouterDOM;
const {
  RevealAnimationContext
} = svs.components.marketplace.revealLink;
class RevealHideTrigger extends React.Component {
  constructor() {
    super(...arguments);
    _defineProperty(this, "handleOnClick", (event, callback) => {
      event.preventDefault();
      setTimeout(() => {
        callback();
      }, 325);
    });
  }
  render() {
    return React.createElement(RevealAnimationContext.Consumer, null, _ref => {
      let {
        triggerHideAnimation
      } = _ref;
      return React.createElement("div", {
        className: "qa-reveal-link reveal-trigger ".concat(this.props.className || ''),
        onClick: event => {
          triggerHideAnimation(true);
          this.props.onClick();
          this.handleOnClick(event, () => {
            triggerHideAnimation(false);
          });
        }
      }, this.props.children);
    });
  }
}
setGlobal('svs.components.marketplace.revealLink.RevealHideTrigger', withRouter(RevealHideTrigger));

 })(window);