(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace/reveal-link/assets/javascripts/reveal-animation-context.js') >= 0) return;  svs.modules.push('/components/marketplace/reveal-link/assets/javascripts/reveal-animation-context.js');
"use strict";


const RevealAnimationContext = React.createContext({
  triggerShowAnimation: () => {},
  triggerHideAnimation: () => {}
});
setGlobal('svs.components.marketplace.revealLink.RevealAnimationContext', RevealAnimationContext);

 })(window);