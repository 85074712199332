(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace/reveal-link/assets/javascripts/revealer.js') >= 0) return;  svs.modules.push('/components/marketplace/reveal-link/assets/javascripts/revealer.js');
"use strict";

function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }

const {
  ContentRevealReact: ContentReveal
} = svs.components;
const {
  RevealAnimationContext
} = svs.components.marketplace.revealLink;
class Revealer extends React.Component {
  constructor(props) {
    super(props);
    _defineProperty(this, "state", {
      shouldShow: false,
      expandMode: true
    });
    this.triggerShow = this.triggerShow.bind(this);
    this.triggerHideAnimation = this.triggerHideAnimation.bind(this);
  }
  triggerShow(show) {
    this.setState({
      shouldShow: show,
      expandMode: true
    });
  }
  triggerHideAnimation(show) {
    this.setState({
      shouldShow: show,
      expandMode: false
    });
  }
  render() {
    return React.createElement(RevealAnimationContext.Provider, {
      value: {
        triggerShowAnimation: this.triggerShow,
        triggerHideAnimation: this.triggerHideAnimation
      }
    }, React.createElement(ContentReveal, {
      className: "reveal-link-content-reveal ".concat(this.state.expandMode ? 'reveal-link-content-reveal-appear' : 'reveal-link-content-reveal-disappear'),
      show: this.state.shouldShow
    }, React.createElement("div", null)), this.props.children);
  }
}
setGlobal('svs.components.marketplace.revealLink.Revealer', Revealer);

 })(window);